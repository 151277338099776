import { onBeforeUnmount, onMounted } from 'vue'

export const useWindowUnload = () => {
  const registerWarnUnloadEvent = (text) => {
    const warnUnload = (e) => {
      e.preventDefault()
      return (e.returnValue = text || '確定離開頁面? 將不會儲存目前工作階段')
    }
    onBeforeUnmount(() => {
      window.removeEventListener('beforeunload', warnUnload, { capture: true })
    })

    onMounted(() => {
      window.addEventListener('beforeunload', warnUnload, { capture: true })
    })
  }

  return { registerWarnUnloadEvent }
}
